import * as actionTypes from './actionTypes';
import axios from '../../axios-alertas';
import { storageUsuarioId } from '../../shared/sessionData';

export const loadAlertasDelUsuario = () => {
  return (dispatch) => {
    dispatch(loadAlertasDelUsuarioStart());

    const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
    axios
      .get(`/notificacion/obtenerNotificaciones?idUsuario=${storageUsuarioId()}`, {
        headers: { Authorization: authStr }
      })
      .then((response) => {

        if(response && response.data) {
          response.data.forEach((item) => {
            if(item.data && item.data !== '') {
              console.log(item.data)
              const itemData = JSON.parse(item.data);
              let itemNotif = Array.isArray(itemData)
                ? itemData[0] : itemData;

              item['type'] = itemNotif.type;
              item['comprobante'] = itemNotif.payload;
            } else {              
              item['type'] = 'alert';
              item['comprobante'] = undefined;
            }
          });
        }

        dispatch(loadAlertasDelUsuarioSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadAlertasDelUsuarioFail(error));
      });
  };
};

export const marcarAlertaComoLeida = (idAlerta, leida) => {
    return (dispatch) => {  
      const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
      axios
        .put(`/notificacion/actualizarNotificacionesLeidas?idNotificacion=${idAlerta}&idUsuario=${storageUsuarioId()}`, {}, {
          headers: { Authorization: authStr }
        })
        .then(() => {
          dispatch(loadAlertasDelUsuario());
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

export const eliminarAlerta = (idAlerta) => {
    return (dispatch) => {  
      const authStr = 'Bearer '.concat(sessionStorage.getItem('token'));
      axios
        .put(`/notificacion/borrarNotificacion?idNotificacion=${idAlerta}&idUsuario=${storageUsuarioId()}`, {
          headers: { Authorization: authStr }
        })
        .then(() => {
          dispatch(loadAlertasDelUsuario());
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

const loadAlertasDelUsuarioStart = () => {
  return {
    type: actionTypes.LOAD_ALERTAS_DEL_USUARIO_START
  };
};

const loadAlertasDelUsuarioSuccess = (alertas) => {
  return {
    type: actionTypes.LOAD_ALERTAS_DEL_USUARIO_SUCCESS,
    alertas
  };
};

const loadAlertasDelUsuarioFail = (error) => {
  return {
    type: actionTypes.LOAD_ALERTAS_DEL_USUARIO_FAIL,
    error
  };
};