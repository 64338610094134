import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PieChart from './PieChart';
import TableChart from './TableChart';

const PieTableChart = (props) => {
  const [viewAsPie, setViewAsPie] = useState(true);
  const onChangeViewClick = () => {
    setViewAsPie(!viewAsPie)
  }

  return viewAsPie
    ? <PieChart
      ids={props.ids}
      title={props.title}
      subTitle={props.subtitle}
      series={props.series}
      labels={props.labels}
      valoresNegativos={props.valoresNegativos}
      loading={props.loading}
      imgSinDatos={props.imgSinDatos}
      height={props.height}
      disabled={props.estaOculto}
      width={props.width}
      onPorcionClick={props.handlePorcionClick}
      chartType={props.chartType}
      onChangeViewClick={onChangeViewClick}
      spaceBetweenChartTitle={props.spaceBetweenChartTitle}
      legendHeight={props.legendHeight}
      legendPosition={props.legendPosition}
      onFiltersClick={props.onFiltersClick}
      minHeight={props.minHeight}
    />
    : <TableChart
      title={props.title}
      subTitle={props.subtitle}
      items={props.items}
      titleName={props.titleName}
      titleField={props.titleField}
      valueName={props.valueName}
      valueField={props.valueField}
      onChangeViewClick={onChangeViewClick}
      height={245}
      width={'100%'}
    />
};

export default withRouter(PieTableChart);