import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';

const initialState = {
  loadAlertas: {
    alertas: [],
    loading: false,
    error: null
  },
}

//#region loadAlertas
const loadAlertasStart = (state) => {
  return updateObject(state, {
    loadAlertas: updateObject(state.loadAlertas, {
      loading: true
    })
  });
};

const loadAlertasSuccess = (state, alertas) => {
  return updateObject(state, {
    loadAlertas: updateObject(state.loadAlertas, {
      alertas,
      loading: false
    })
  });
};

const loadAlertasFail = (state, error) => {
  return updateObject(state, {
    loadAlertas: updateObject(state.loadAlertas, {
      loading: false,
      error
    })
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //#region LOAD CLIENTES
    case actionTypes.LOAD_ALERTAS_DEL_USUARIO_START:
      return loadAlertasStart(state);
    case actionTypes.LOAD_ALERTAS_DEL_USUARIO_SUCCESS:
      return loadAlertasSuccess(state, action.alertas);
    case actionTypes.LOAD_ALERTAS_DEL_USUARIO_FAIL:
      return loadAlertasFail(state, action.error);
    //#endregion

    default:
      return state;
  }
};

export default reducer;